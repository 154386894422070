[data-theme='book'] header[data-extent='frontmatter'] .core-container {
    max-width: 80rem;
    width: 95%;
    clear: both;
    margin-left: auto;
    margin-right: auto;

    .core-book-title {
        background: $secondary-lighten-color;
        display: flex;
        flex-wrap: wrap;
        font-size: $font-size-xl;
        font-style: italic;

        > div {
            @include renderPseudos('frontmatter-div', $article-self-citation-styles);
            &:before {
                height: 0.5rem;
                width: 0.5rem;
            }
        }

        h1 {
            padding-left: 0.875rem;
            font-style: italic;
            position: relative;
            @include headingWithLeftBorder($selector: 'article-h2');
        }

        .core-date-published {
            transform: none;
        }
    }

    .core-book-citation {
        display: grid;
        grid-template-columns: auto 2fr 2fr;
        background-color: $dark-3;
        padding: 1rem;

        > div:not(:empty) {
            padding: 1rem;
        }

        .core-book-cover {
            .overlay-cover-wrapper {
                img {
                    width: 8.375rem;
                }

                @include media('<#{$screen-md}') {
                    display: none;
                }
            }
            @include media('<#{$screen-md}') {
                padding: 0 !important;
            }
        }

        .core-book-information {
            .heading {
                font-weight: $font-weight-semi-bold;
                font-size: $font-size-sm;
                color: $dark-2 !important;
            }

            .contributors,
            .core-published {
                border: none;
            }

            .core-published {
                .core-crossmark-badge {
                    display: inline-block;
                    vertical-align: sub;
                    margin-left: 0.5rem;
                }

                .core-date-published {
                    color: $dark-2 !important;
                }
            }

            .contributors {
                .authors:first-child {
                    span.heading {
                        width: 5.8rem;
                        display: inline-block;
                        text-align: right;
                    }
                }
            }

            .doi {
                line-break: anywhere;

                a {
                    color: $primary-lighten-color;

                    &:hover,
                    &:focus,
                    &:active {
                        color: $article-link-color;
                    }
                }
            }

            .core-book-publisher-meta,
            .core-book-page-count {
                display: grid;
                grid-template-columns: 6rem auto;
                grid-column-gap: 0.5rem;

                .heading {
                    grid-column: 1 / 2;
                    text-align: right;
                }
            }
        }

        .core-book-meta {
            display: flex;

            @include media('<#{$screen-md}') {
                flex-flow: column;
            }

            .core-book-formats,
            .core-book-meta-panel {
                width: 100%;
                height: 100%;
                margin: auto;
                border-left: $border-solid-style $dark-7;
                padding: 0 1rem;

                &:empty {
                    display: none;
                }

                .btn {
                    justify-content: center;
                    margin-top: 0.5rem;
                    width: 100%;
                    margin-left: 0;
                }

                .core-book-actions {
                    .btn {
                        background-color: transparent;
                        border: $border-solid-style $dark-2;
                        color: $primary-lighten-color;
                        flex-wrap: wrap;
                    }

                    @include media('<#{$screen-md}') {
                        /*<992 ACM default for mobile view, some button change at this width*/
                        display: flex;
                        justify-content: space-between;

                        > div {
                            width: 100%;

                            &:not(:last-child) {
                                margin-right: 0.5rem;
                            }
                        }

                        .btn {
                            padding: 0.5rem 0;
                        }
                    }
                }

                .core-book-share {
                    margin-top: 0.5rem;

                    @media (max-width: 47.94rem) {
                        .meta-panel__share {
                            display: unset !important;
                        }
                    }
                }

                @include media('<#{$screen-md}') {
                    border-left: none;
                    border-top: $border-solid-style $dark-7;
                    padding: 1rem;
                }
            }
        }

        .info-panel__formats,
        .core-book-meta-panel {
            display: flex;
            flex-direction: column;
            height: 100%;
            justify-content: center;
        }

        @include media('<#{$screen-md}') {
            grid-template-columns: 1fr;
            grid-row-gap: 1rem;
        }
    }
}
