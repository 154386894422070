[data-core-nav='header'] {
    top: var(--article-sticky-top, 7.75rem);
    transition: top 0.5s;

    .icon-metric {
        font-size: 0.813rem;
    }

    @include media('<' + $navigation-breakpoint) {
        [aria-controls] {
            font-size: 0.5rem;
        }
    }

    .icon-Icon_Links-References,
    .icon-Icon_Images {
        font-size: 1.125rem;
    }

    header {
        padding: 0.625rem 1rem 0;

        + ul {
            border-top: $default-border;
        }
    }

    .core-self-citation {
        line-height: 1.25rem;
        font-style: italic;
        [property='name'] {
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
        }
        .core-publication-title {
            color: $primary-lighten-color;
        }
        [property='pagination'] {
            font-weight: $font-weight-light;
        }
    }

    .content-navigation {
        font-size: $font-size-xs;
        .icon-Arrow-Stroke:before {
            font-size: 0.438rem;
        }
        &__prev {
            .icon-Arrow-Stroke:before {
                transform: rotate(180deg);
            }
        }
    }

    footer:not(:empty) {
        background-color: $secondary-bg;
        padding-block: 0.5rem;
    }
}

.st-header {
    .article__breadcrumbs,
    .content-navigation {
        color: $st-header-color;
    }
}

#article_sections_menu ul {
    li {
        margin: $section-navbar-list-link-margin;
        a {
            margin: 0;
        }
    }

    ul {
        height: 0;
        overflow: hidden;
        padding-block-start: 0;
        padding-inline-start: 0.5rem;
    }

    .collapsible-button {
        min-height: auto;
        min-width: 0;
    }

    .has-menu {
        &:has([aria-current='true']) > a:before {
            opacity: 1;
            transform: scale(1);
        }
        [aria-expanded='true'] + ul {
            margin-block-start: -0.5rem;
        }
    }
}
