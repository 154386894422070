@import '~ui-article/axel-publication-content/scss/colors';

$article-link-color: #0071b8;
$article-link-color-hover: $article-link-color;
$access-free: #508023;
$get-access: #448500;
$get-access-hover: #2d7130;
$twitter: #000;

$dark-4: $secondary-lighten-color;
$gray: $primary-lighten-color;
$secondary: $primary-lighten-color;
$secondary-bg: $light-gray-color;
$access-open: $orange-open-access;
$access: $success-text-color;
$success: $success-text-color;
$border-color: $dark-4;
$highlight: $accessibility-bg;

$font-weight-semi-bold: 600;

$article-sans-serif-font: 'Open Sans', sans-serif;

$default-border: $border-solid-style $border-color;
$default-dashed-border: $border-dashed-style $border-color;
$light-border: $default-border;

$section-navbar-x-offset: -12px; /* stylelint-disable-line unit-disallowed-list*/
$menubar-y-position: calc(100% + #{$menubar-y-offset});

// ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~

$article-body-color: unset;
$collateral-header-dark: false;
$collateral-tabs-bordered: true;
$has-collateral-h4-left-border: true;
$has-colored-section-navbar: false;
$has-article-custom-scrollbars: true;

$accessibility-focus-box-shadow: 0 0 0.063rem 0.25rem rgba($article-link-color, 0.3);
