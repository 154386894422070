// TODO delete this file after adding the icons to Axel side

.icon-info:before {
    content: '\e949';
}
.icon-timeline:before {
    content: '\e90b';
}
.icon-photo:before {
    content: '\e96a';
}
.icon-tables:before {
    content: '\e904';
}
.icon-share:before {
    content: '\e941';
}
.icon-list:before {
    content: '\e96f';
}
