.core-supplementary-materials {
    h2 + div {
        margin-top: 0.875rem;
    }

    h3 {
        padding-top: 1.563rem;
        margin-bottom: 0.625rem;
    }

    .core-supplementary-material {
        .core-video {
            display: flex;
            flex-wrap: wrap;
            gap: 0.5rem 2rem;

            .figure-wrap[data-type='media'] {
                margin: 0;
                margin-top: $article-suppl-link-margin-top;
            }
        }
    }
}
