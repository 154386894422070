.fv {
    &__slideshowNav {
        &.disabled {
            pointer-events: initial;
            opacity: 1;
        }
    }

    &__slideshow__item {
        iframe {
            height: inherit;
        }

        figcaption {
            text-align: left;
        }
    }

    &__infoPanel .fv__panel__contentText figcaption {
        text-align: left;
    }
}
