header[data-extent='frontmatter'] {
    .core-published {
        @include renderRules('published', $article-self-citation-styles);
        @include renderCtaStyles('published-links', 'a', $article-self-citation-styles);
        @include articleElementStyles('published', '.label', $article-self-citation-styles, 'heading');

        .crossmark__link {
            margin-inline-start: 2.5rem;
            vertical-align: middle;
            @include media('<tablet') {
                margin-left: 0;
            }
        }
    }
}
