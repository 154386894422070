.core-xlink {
    &-crossref,
    &-digital-library,
    &-google-scholar {
        line-height: 1;
        a:before {
            margin-right: 0.5rem;
        }
    }
    &-crossref a:before {
        content: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="13" height="18" viewBox="0 0 13 18" fill="none"><path d="M0 18.0001V12.5737L4.69113 11.0989L13 13.8027L0 18.0001Z" fill="%233EB1C8"/><path d="M8.30887 6.90137L0 9.62406L4.69113 11.0988L13 8.37616L8.30887 6.90137Z" fill="%23D8D2C4"/><path d="M4.69141 11.0987L13.0003 8.37598V13.8025L4.69141 11.0987Z" fill="%234F5858"/><path d="M13 0V5.42647L8.30887 6.90126L0 4.19748L13 0Z" fill="%23FFC72C"/><path d="M8.30887 6.90129L0 9.62398V4.19751L8.30887 6.90129Z" fill="%23EF3340"/></svg>');
    }
    &-digital-library a:before {
        content: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Layer_1" x="0px" y="0px" width="20" height="20" viewBox="0 0 136.8 136.8" style="enable-background:new 0 0 136.8 136.8;" xml:space="preserve"><style type="text/css">.st0{fill:%235A8641;}</style><g><polygon class="st0" points="51,11.7 32.6,6.9 27.8,25.2 41.1,21.6 "/><polygon class="st0" points="35.6,27.2 9.2,34.4 13.3,49.4 "/><polygon class="st0" points="125.2,51.1 130.1,32.4 111.8,27.7 115.5,41.6 "/><polygon class="st0" points="11.9,86 7.1,104.4 25.4,109.1 21.8,95.8 "/><polygon class="st0" points="102.6,9.1 87.3,13.3 109.8,35.8 "/><polygon class="st0" points="101.2,109.7 127.9,102.4 123.8,87.1 "/><polygon class="st0" points="85.9,125 104.5,129.9 109.4,111.6 95.5,115.3 "/><polygon class="st0" points="34.6,127.7 49.6,123.7 27.5,101.5 "/><g><path class="st0" d="M96.8,28.4L81.2,12.8L68.4,0L46.1,22.3L12.9,55.5L0,68.4l22.5,22.5l33.2,33.2l12.7,12.7l22.2-22.2L124.2,81 l12.6-12.6L96.8,28.4z M66.7,81.9L61.5,87c-0.9,0.9-1.9,1.3-3.1,1.3H36.2V46.6h22.1c1.2,0,2.3,0.4,3.1,1.3l5.1,5.1 c0.9,0.9,1.3,1.9,1.3,3.1v22.7H68C68,80,67.6,81.1,66.7,81.9z M108.2,88.3h-28V46.6h7.5v34.6h20.5V88.3z"/><path class="st0" d="M57.6,54.1c-0.2-0.2-0.5-0.4-0.9-0.4h-13v27.5h13c0.4,0,0.6-0.1,0.9-0.4l2.4-2.4c0.2-0.3,0.4-0.6,0.4-0.9 V57.4c0-0.3-0.1-0.6-0.4-0.9L57.6,54.1z"/></g></g></svg>');
    }
    &-google-scholar a:before {
        content: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none"><path d="M19 2.983C19.008 1.929 18.11.99 17.055 1c-4.763.046-9.526.02-14.29.02-1.016 0-1.764.755-1.765 1.778v14.128c0 1.326.742 2.072 2.076 2.072 4.582.003 9.164.002 13.745 0 1.374 0 2.173-.804 2.174-2.184.002-2.437 0-4.873 0-7.31 0-2.174-.011-4.348.005-6.521zm-2.81-.49c-.616.634-.668 1.095-.24 1.9.071.136.079.317.08.477.005 1.416-.004 2.83.007 4.246.004.397-.104.695-.53.754-.339.047-.583-.277-.584-.752-.004-1.415-.008-2.83.006-4.246.002-.2.117-.397.164-.598.045-.191.069-.387.102-.58a85.709 85.709 0 01-.137-.066c-.586.587-1.18 1.167-1.75 1.77-.074.077-.054.277-.025.41.287 1.309-.04 2.405-1.092 3.268a6.226 6.226 0 00-.72.673c-.392.442-.38.854.052 1.255.482.447.998.857 1.494 1.29 1.594 1.386 1.203 3.495-.165 4.657-.816.693-1.778 1.06-2.829 1.188-1.206.146-2.403.126-3.543-.366-.986-.424-1.72-1.097-1.777-2.226-.055-1.088.47-1.917 1.39-2.504.977-.623 2.078-.798 3.196-.925.13-.015.262-.023.392-.04.026-.004.047-.037.116-.095-.456-.526-.595-1.141-.363-1.875-2.242.047-3.599-.923-3.814-3.304H2.54c.229-.242.346-.373.47-.498 1.293-1.292 2.581-2.59 3.887-3.868.158-.154.413-.295.625-.297 2.882-.02 5.765-.013 8.647-.012.076 0 .15.01.303.022-.127.153-.198.254-.283.341z" fill="%232189CA"/><path d="M10.335 12.678c-.931-.009-1.845.1-2.69.522-1.468.734-1.61 2.496-.285 3.463.71.518 1.53.661 2.405.778.587-.14 1.21-.2 1.752-.44 1.288-.567 1.57-2.044.599-3.058-.418-.435-.933-.78-1.414-1.15a.648.648 0 00-.367-.115zM8.712 4.053c-.661.197-1.073.639-1.198 1.31-.222 1.196.066 2.289.776 3.262.56.766 1.328 1.026 2.116.765.82-.271 1.196-.862 1.2-2.111-.05-.923-.349-1.974-1.215-2.802-.482-.46-1.035-.616-1.679-.424z" fill="%232189CA"/></svg>');
    }
}
