.contributors {
    .core-profile-image {
        border-radius: 50%;
        box-sizing: content-box;
        max-width: 100%;
        object-fit: cover;
        object-position: top;
        margin-right: 0.5rem;
        vertical-align: middle;
        width: 1.5rem;
        height: 1.5rem;
    }

    .dropBlock__holder {
        &.js--open {
            display: inline-block;
        }

        background: $white;
        border: 0.063rem solid #d9d9d9;
        border-radius: 0.25rem;
        box-shadow: 0 0 0.25rem rgba(0, 0, 0, 0.12);
        max-width: 19.75rem;
        padding: 0;
        position: absolute;
        transition: 0.3s;
        width: 19.75rem;

        @include media('<=tablet') {
            transform: translateX(-50%);
            left: 50%;
        }

        .heading {
            background: $dark-4;
            border-bottom: 0.063rem solid #d9d9d9;
            color: $primary-lighten-color;
            display: flex;
            font-weight: 200;
            padding: 0.625rem;

            .core-profile-image {
                margin-right: 0;
            }
            [property='name'] {
                padding: 0.1rem 0 0 0.5rem;
            }
        }

        .content {
            padding: 0 1rem 1.438rem;

            .affiliations {
                width: 16rem;
                line-height: 1.188rem;
                margin-top: 0;

                [property='affiliation'] {
                    padding-bottom: 0.5rem;
                    ~ [property='affiliation'] {
                        padding-top: 0.875rem;
                        border-top: 0.063rem solid #d9d9d9;
                    }
                }
            }

            .core-author-link a {
                background-color: transparent;
                border: 0.063rem solid $primary-color;
                color: $primary-color;
                border-radius: 0.125rem;
                clear: both;
                cursor: pointer;
                font-size: 0.813rem;
                height: 2.188rem;
                line-height: 1.188rem;
                padding: 0.5rem 1.625rem;
                text-align: center;
                width: max-content;
                text-decoration: none;
                &:hover {
                    background-color: $dark-4;
                    color: $register-btn-bg;
                }
            }

            .core-author-link a.profile-link {
                background-color: $blue-color;
                border: none;
                color: $white;
            }

            .core-orcid-link {
                @include renderRules('orcid', $article-link-styles);
                @include renderPseudos('orcid', $article-link-styles);
                @include iconfont-icon;
            }
        }
    }
}
