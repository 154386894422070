.meta-panel {
    &__access {
        &--chorus {
            color: $journal-code-color;
        }
    }
    &__badges {
        img {
            width: 1.5rem;
        }
    }
}

.info-panel {
    &__binder {
        position: relative;
    }

    .saveToBinders {
        i {
            font-size: $font-size-base;
        }

        + [data-db-target-of] {
            position: absolute;
            top: 100%;
            left: 100%;
            z-index: $index-10;

            @include media('<phone') {
                left: unset;
                transform: translateX(85%);
            }
        }
    }

    .article-actionbar__btn {
        i {
            margin-inline-start: 0;
            margin-inline-end: 0;
        }
    }
}
