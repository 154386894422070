@import '~ui-article/axel-publication-content/scss/axel-publication-content';

@import 'acm-article';
@import 'acm-book-header';
@import 'acm-citations';
@import 'acm-collateral';
@import 'acm-contributors';
@import 'acm-figures';
@import 'acm-figure-viewer';
@import 'acm-meta-panel';
@import 'acm-navigation-menus';
@import 'acm-self-citation';
@import 'acm-share';
@import 'acm-supplementary-material';

@import 'acm-icons';

:root {
    --article-sticky-top: 11.5rem;
    --scroll-padding: var(--article-sticky-top, 11.5rem);
}
