/* Styles should be applied only with the Axel is applied ( ACM test ) */
$index-3: 3;

/* binder-create.scss */
.binder__create {
    &-btns-container {
        li {
            .btn {
                font-weight: 400;
                justify-content: center;
                margin-left: toRem(4);
                padding-block: toRem(18);
                text-transform: none;
            }

            .binder__create-cancel {
                margin-left: 0;
            }
        }
    }
}

/* cited-by.scss */
.cited-by {
    & &__title:is(h2) {
        background: unset;
        border-color: var(--primary-color);
        line-height: unset;
    }
}

.progress-bar--wrapper {
    display: none;
}

/* pill.scss */
[class*='pill'][class*='__content']:not(.pill-media__content) {
    height: unset;

    .section {
        &__separator {
            padding: toRem(25) 0;

            @include media('<tablet') {
                padding: toRem(25) 0;
            }
        }

        &__content {
            padding-left: 0;
        }
    }
}

.share-this-popover {
    z-index: $index-3;
}

[data-design] {
    #abstracts > .core-container > section:not(:first-child) {
        margin-top: 2em;
    }

    .core-title-label {
        font-size: $font-size-md;
        font-weight: $font-weight-semi-bold;
        line-height: 1;
        margin-block: 0.25rem;
    }

    .core-jats-code {
        &:before {
            content: 'JATS';
            display: block;
            font-weight: $font-weight-bolder;
            text-decoration: underline;
        }
        background: whitesmoke;
        padding: 1em;
        overflow: scroll;
        border: $border-solid-style silver;
        resize: both;
        height: 12.5rem;
        margin-bottom: 3em;
        pre {
            font-family: Open Sans, sans-serif;
            font-size: 1em;
            line-height: 1.4;
        }
    }

    .colored-block__title,
    .relatedDo {
        @include articleHeading($prefix: 'colored-block-', $map: $article-heading-styles, $level: 'h2');
        h2#sec-reviews {
            padding: 0.75rem 0 0.75rem 1rem;
        }
    }

    .organizational-chart {
        a {
            color: inherit;
            text-decoration: none;
        }

        p {
            margin-block: 1em;
            line-height: 1.5;
        }
    }

    .tooltip:hover .tooltip__body {
        @include media('>=phone') {
            line-height: 1;
            top: 100%;
        }
        a {
            text-decoration: unset;
        }
        .info {
            padding: 0.625rem;
        }
    }

    a[href^='http']:not(.btn):visited {
        color: $visted-link-color;
    }

    section[data-type='biography'] {
        img {
            float: left;
            margin-inline-end: 0.625rem;
        }
    }

    [data-type='biography'] {
        border-top: 0.125rem solid $border-color;
        font-size: 0.8em;
        line-height: normal;

        img {
            margin-block-end: 0.625rem;
        }
        figcaption,
        img {
            max-width: 6.25rem;
        }
    }

    div[data-type='biography'] {
        clear: both;
        figure {
            width: fit-content;
            float: left;
            img {
                padding: 0;
            }
        }
    }

    [role='list']:has([data-style='left-column']) {
        display: flex;
        margin-block-end: 1rem;
    }

    section {
        display: flow-root;
    }
}

[data-style^='badge'] {
    background: $dark-4;
    display: inline-block;
    margin: 0 0.13rem 0.25rem;
    padding: 0.313rem 0.63rem;
}

[data-style='highlight'] {
    background-color: $highlight-color-new;
}

[data-position='anchor'] {
    float: left;
    width: 30%;
}

[data-type='pull-left'],
[data-style='pull-left'] img {
    float: left;
}

[data-type='pull-right'],
[data-style='pull-right'] img {
    float: right;
}

[data-type='inline-headings-section'] {
    [data-type='inline-heading'] {
        [role='heading'] {
            font-weight: $font-weight-bold;
        }
    }
}

[role='listitem'] {
    .label {
        color: inherit;
        font-size: inherit;
        font-weight: inherit;
        margin-bottom: 0;
    }
}

blockquote {
    &[data-type='factoid'] {
        border-bottom: thin solid;
        margin: 0 15%;
        padding: 0.5rem;

        div[role='paragraph'] {
            font-size: 1em;
            font-weight: 700;
            line-height: 1.4;
            margin: 1em 0;

            span.inline-graphic {
                > img {
                    float: left;
                    height: 4rem;
                    padding: 0 0.5rem;
                    width: auto;

                    &[data-type='pull-right'] {
                        float: right;
                    }
                }
            }
        }
    }

    &[data-type='left-bordered'] {
        border-left: 0.5rem solid $xrds-primary-color;
        margin: 1em 20%;
        overflow: auto;
        padding: 0 0.75rem;

        div[role='paragraph'] {
            font-size: 1.063rem;
            font-weight: 300;
            line-height: 2.13rem;
            margin: 0.75em 0;
        }
    }

    &[data-type='pull-quote'] {
        background: initial;
        margin: 0 20%;
        padding: 0.25rem 0;
        position: relative;

        div[role='paragraph'] {
            font-size: 1.1rem;
            font-weight: 700;
            line-height: 2.13rem;
            margin: 1em 0;
        }

        &::before {
            background-color: $xrds-primary-color;
            content: '';
            display: inline-block;
            height: 0.5rem;
            left: 0;
            position: absolute;
            top: 0;
            width: 2.25rem;
        }
    }

    &[data-type='quote'] {
        background: $dark-3;
        font-style: normal;
        margin: 1.5em 20%;
        max-height: none;
        padding: 1.25rem 2.5rem;
        position: relative;
        text-indent: 0;

        &::after,
        &::before {
            font-family: 'Liberation serif', sans-serif;
            font-size: 3.45rem;
            height: 1.313rem;
            position: absolute;
            width: 1.313rem;
        }

        &::after {
            bottom: 1.88rem;
            color: $xrds-primary-color;
            content: close-quote;
            right: 0.63rem;
        }

        &::before {
            color: $xrds-primary-color;
            content: open-quote;
            left: 0.5rem;
            top: 0;
        }
    }
}

footer .back-to-top {
    border: none;
    color: $lighter;
}

// Reviews styling should be removed when we apply Axel on stag and prod

#sec-reviews {
    .colored-block__title {
        padding: 0;

        .section__title {
            font-weight: 600;
            background-color: $secondary-lighten-color;
        }
    }
    .reviews__item {
        .reviewer {
            margin-top: toRem(12);
        }
    }
    .article__computing-reviews {
        p {
            margin: 0;
            font-size: toRem(14);
            line-height: toRem(23);
            font-weight: 300;
        }

        a {
            color: $blue-color;
            text-decoration: underline;
        }

        h4 {
            margin-bottom: toRem(5);
            margin-top: toRem(7);
            font-variant: unset;
            padding-top: 0;
            color: $primary-color;
        }
    }
    .accordion-tabbed__content {
        padding: 0;
        .card--dark {
            padding-bottom: 0;
        }
    }
}

// Removes all margin top and bottom on UX3 widgets LIT-562520

#sec-terms .article__index-terms {
    margin-block-start: 0;
}
#sec-recommendations {
    .recommended-articles__content {
        padding-bottom: 0;
    }

    h5 {
        padding: 0;
    }
}

article[data-design] {
    .accordion__control {
        padding: 0;
        border: none;
        text-align: left;
        align-items: center;
        background-color: map-get($article-heading-styles, 'frontmatter-h2-background-color');
        display: flex;
        text-decoration: unset;

        h2 {
            flex-grow: 1;
        }

        i[class*='icon-'] {
            margin: 0.75rem;
        }
    }

    .accordion__content {
        background-color: $white;
    }

    .to-citation__wrapper {
        .to-citation,
        .to-citation__toggle {
            border: none;
        }
    }
}

.core-key-image {
    clear: both;
    float: inline-start;
    margin-block-start: 1.5rem;
    margin-inline-end: 1.25rem;

    img {
        box-shadow: 0 0 0.63rem $secondary-shadow-color;
        height: auto;
        max-width: 7.5rem;
        width: auto;
    }
}

[data-extent='backmatter'],
[data-extent='frontmatter'] {
    h2 {
        @include headingWithLeftBorder($selector: 'article-h2');
    }
}

[data-core-nav='header'][data-extent='frontmatter'] + [data-extent='bodymatter'] {
    .core-container > [role='paragraph']:first-child {
        margin-top: 2rem;
    }
}

[data-extent='bodymatter'] {
    article.focused {
        box-shadow: none;
    }
}

[data-columns='2'] {
    columns: 1;
    display: grid;
    grid-auto-flow: row;
    row-gap: 0.63rem;
    margin-bottom: 1rem;

    @include media('>=tablet') {
        column-gap: 0.63rem;
        columns: 2;
        grid-auto-flow: column;
    }
}

[role='doc-abstract'][data-type='main'] {
    background-color: $secondary-bg;
    padding: 0 1.875rem 1.875rem;

    h2 {
        margin-inline: -1.875rem;
    }
}

[data-type^='bullet'] {
    display: table;
    margin-top: 1em;

    > .heading:first-child {
        display: table-caption;
    }

    [role='listitem'] {
        display: table-row;
    }

    [role='paragraph']:last-child {
        margin-block-end: 1em;
    }

    span[data-style='badge-rounded-edgs'] {
        background-color: $xrds-primary-color;
        border-radius: 0.25rem;
        color: $sub-nav;
        font-size: $font-size-xs;
        text-shadow: 0.063rem 0.063rem 0.13rem rgba(0, 0, 0, 0.4);
    }

    .content {
        display: table-cell;
        width: inherit;
    }

    .inline-graphic {
        display: inline-block;
        margin-block: 1em;
    }
}
[data-type^='bullet-top'] {
    .label {
        vertical-align: top;
    }
}
[data-type='bullet-bottom'] {
    .label {
        vertical-align: bottom;
    }
}

[data-type='closing-para'] {
    font-size: 0.875em;
}

[data-type='colored-para'] {
    color: $success;
}

[data-type='leading-para'] {
    font-size: 1.2em;
}

.btn--fullText {
    &:hover,
    &:focus,
    &:active {
        color: $white;
        background-color: lighten($dark-2, 10%);
    }
}

#ACM_widgets {
    section {
        margin-top: 2rem;
        #disqus_thread iframe {
            margin-top: 0.5rem;
        }
    }
}

#downloadAll {
    text-decoration: none;
    min-height: 0;
}

.core-lede {
    font-weight: 600;
    line-height: 1.5;
}

.core-related-do {
    border-block-start: $default-border;

    line-height: 1.25;

    .border-bottom {
        border-bottom-width: 0;
        padding-bottom: 0;
    }

    .bold {
        font-weight: $font-weight-semi-bold;
    }

    .dot-separator:before {
        font-size: 0.875rem;
        color: $black;
    }

    .label {
        margin-right: 0;
    }

    .epub-section__title:hover {
        color: $article-link-color;
    }

    a,
    span {
        font-size: 0.875rem;
        color: $primary-lighten-color;
    }
    a:hover {
        color: $article-link-color;
    }

    @media (max-width: 63.92rem) and (min-width: 44.33rem) {
        .issue-item__detail span {
            display: inline;
        }
    }
}
