@import '~ui-article/axel-publication-content/scss/variables';

// article
$article-link-decoration: underline;
$icon-content_copy: '\e963';
$article-button-font-size: 0.875rem;
$article-button-text_decoration: none;
$has-subtitle-styles: false;

$article-link-hover-color: $dark-blue-link;
$article-default-link-color-hover: $dark-blue-link;
$article-button-font-weight: unset;
$article-button-max-height: 2.5rem;
$article-button-min-height: 2.5rem;
$article-button-min-width: 2.5rem;
$article-button-textTransform: unset;
$article-button-bordered-border: $border-solid-style $dark-2;
$article-button-bordered-hover-border: $article-button-bordered-border;
$article-button-primary-background: $article-link-color;
$article-button-primary-hover-background: $dark-blue-link;
$article-button-access-background: $get-access;
$article-button-access-hover-background: $get-access-hover;

// bodymatter
$article-bodymatter-section-margin-top: 0;

// article scrollbar
$article-scrollbar-scrollbar-radius: 0.5rem;
$article-scrollbar-track-radius: 0.5rem;
$article-scrollbar-width: 0.5rem;
$article-scrollbar-thumb-color: var(--primary-color, $primary);
$article-scrollbar-thumb-color-hover: rgba(0, 0, 0, 0.5);

$article-footnotes-citations-font-weight: unset;

//keywords
$keywords-font-size: unset;

// ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~

// Section Navigation
$section-navbar-core-enumeration-margin: 0.25rem 0;
$section-navbar-content-navigation-margin: 1rem 0;
$section-navbar-filter: drop-shadow(0 0.125rem 0.375rem rgba(0, 0, 0, 0.16));
$section-navbar-footer-margin: unset;
$section-navbar-font-family: $default-font-family;
$section-navbar-list-link-active-color: var(--primary);
$section-navbar-list-link-before-border-radius: unset;
$section-navbar-list-link-before-color: var(--primary-color, $primary);
$section-navbar-list-link-before-height: 1rem;
$section-navbar-list-link-before-left: -0.25rem;
$section-navbar-list-link-before-top: 0;
$section-navbar-list-link-margin: 0.875rem 0;
$section-navbar-list-link-padding: 0 0 0 0.5rem;
$section-navbar-list-margin: unset;
$section-navbar-padding: 0;
$section-navbar-subdued-list-background: unset;
$section-navbar-title-font-size: $font-size-sm;
$section-navbar-title-font-weight: $font-weight-semi-bold;
$section-navbar-title-margin-bottom: 0.25rem;
$hide-content-navigation-links: false;
$section-navbar-collapse-enabled: true;

// ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~

// collateral pill
$article-icon-eye-correction: inherit;
$collateral-p-margin: 0 0 1em;
$collateral-tabnav-link-text-transform: unset;
$collateral-tabnav-link-font-weight: unset;
$collateral-tabnav-link-background-hover: $dark-4;
$collateral-tabnav-link-color: $dark-2;
$collateral-tabnav-link-color-hover: $black;
$pill-menu-box-shadow: 0 0 0.125rem rgba(0, 0, 0, 0.16);

// collateral
$collateral-font-family: $default-font-family;
$collateral-line-height: 1.4;
// core-collateral - pane tabs
$collateral-tabnav-border-bottom: 0.25rem solid var(--secondary);
$collateral-tabnav-link-margin: unset;
$collateral-tabnav-link-radius: unset;
$collateral-tabnav-padding: 0;
// core-collateral - accordion
$pill-acc-control-font-size: $font-size-xs;
$pill-acc-control-size: 1.5rem;

$pill-cover-image-margin-right: unset;
$pill-cover-image-max-width: unset;

// ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~

// sticky-header
$st-header-breadcrumbs-color: unset;
$st-header-track-background: $secondary-color;
$st-header-tracker-background: $black;

// ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~

// article - supplementary material
$article-download-background-color: var(--btn-inverse-background);
$article-download-border: var(--btn-inverse-border);
$article-download-border-radius: 0;
$article-download-color: var(--btn-inverse-color);
$article-download-hover-background-color: var(--btn-inverse-background-hover);
$article-download-hover-color: var(--btn-inverse-color-hover);

// article - go-to citation link
$article-citations-links-margin-top: 0;

// figures
$article-figure-figcaption-border: unset;
$article-figure-figcaption-color: $dark-2;
$article-figure-figcaption-line-height: 1.2;
$article-figure-figcaption-font-weight: $font-weight-semi-bold;
$article-figure-figcaption-padding: unset;
$article-figure-footnote-color: unset;
$article-figure-footnote-font-size: unset;
$article-figure-footnote-line-height: unset;
$article-figure-heading-font-weight: $font-weight-semi-bold;
$article-figure-padding: 1rem;
$article-figure-wrapper-background: $light-gray-color;
$article-figure-wrapper-border: unset;
$article-figure-wrapper-box-shadow: unset;
$article-figure-wrapper-font-size: unset;
$article-figure-btn-font-size: 2rem;
$article-figure-btn-size: 3.5rem;
$article-figure-header-label-weight: 500;
