%gray-links {
    a:not(.btn) {
        color: $gray;
        &:focus-within,
        &:hover {
            color: $dark-2;
        }
    }
}

.core-collateral {
    [data-expandable='item'] > .content {
        margin: 0;
    }

    .core-qualifiers,
    .core-sponsors,
    .core-in-cooperation,
    .core-funding {
        li {
            padding-bottom: 0.25rem;
        }
    }

    .core-collateral-crossmark {
        h4 {
            display: inline-block;
        }
        .crossmark__link {
            vertical-align: middle;
            margin-left: 3rem;
        }
    }

    .core-badges {
        @extend %gray-links;

        img {
            margin-inline-end: 1.5rem;
            max-width: 4rem;
        }

        li ~ li {
            margin-block-start: 0.625rem;
        }
    }

    .core-conference {
        display: grid;
        grid-gap: 0 1rem;
        @include media('>=phone') {
            grid-template-columns: 1fr 3fr;

            h4 {
                grid-column: 1/3;
            }
        }

        &-calender,
        &-map {
            color: $gray;
            font-size: $font-size-sm;
            font-style: italic;
            font-weight: $font-weight-semi-bold;
            margin-top: 0.625rem;

            i {
                padding-inline-end: 0.625rem;
            }
        }

        &-code {
            align-items: center;
            background: $dark-4;
            color: $dark-2;
            display: inline-flex;
            font-size: 0.938rem;
            font-weight: $font-weight-semi-bold;
            justify-content: center;
            line-height: 1.25;
            min-height: 2.875rem;
            padding: 0.313rem;
            word-break: break-word;
            @include media('>=phone') {
                width: 100%;
            }
        }

        &-left {
            text-align: center;
            a {
                text-decoration: underline;
            }

            @include media('<phone') {
                display: table;
                margin-block-end: 1rem;
                min-height: 2.5rem;
                padding-inline: 1rem;

                > * {
                    display: table-cell;
                    vertical-align: middle;
                    width: 50%;
                }
            }
        }

        &-right {
            a {
                color: $dark-2 !important;
                font-size: $font-size-base;
                margin-bottom: 0.625rem;
                font-weight: $font-weight-normal;
                text-decoration: none;

                @include media('<phone') {
                    border-block: $default-border;
                    display: block;
                    padding-block: 0.625rem;
                }
            }
        }

        &-sponsors {
            border: $default-border;
            border-width: 0.125rem;
            padding: 0.313rem;
            font-size: 0.688rem;
            &-label {
                font-weight: $font-weight-semi-bold;
                @include media('<phone') {
                    display: none;
                }
            }
        }

        ul {
            list-style: none;
            padding: 0;
            margin: 0;
        }
    }

    .upcoming-conf {
        .event__title {
            text-decoration: none;
            font-weight: $font-weight-normal;
        }
    }

    .core-journal-family {
        @extend %gray-links;
    }

    .core-history {
        .core-enumeration {
            margin-top: 0.75rem;
        }
    }

    .cover-image {
        display: grid;
        gap: 1rem;

        + .cover-multiple-parents {
            border-top: $border-solid-style $dark-7;
            margin-top: 1.25rem;
            padding-top: 1.25rem;
        }

        @include media('>=phone') {
            grid-template-columns: 1fr 2fr;
        }

        img {
            float: none;
            padding: 0;
            @include media('<phone') {
                max-width: 5rem;
            }
        }
        img.author-picture {
            height: 1.5rem;
            margin: 0 0.25rem 0 0.5rem;
        }

        .journal-meta span,
        .book-meta {
            font-size: $font-size-md;
            font-weight: $font-weight-semi-bold;
            margin-block-end: 0.5em;
            display: block;
        }

        .cover-date,
        .cover-pages {
            display: inline-block;
            margin-block-end: 0.5em;
        }

        .cover-date + .cover-pages {
            border-inline-start: $default-border;
            line-height: 1;
            margin-inline-start: 0.5em;
            padding-inline-start: 0.5em;
        }

        .flex-container ~ .flex-container {
            margin-block-start: 0.5em;
        }

        .label {
            color: $dark-2;
            display: unset;
            font-size: unset;
            font-weight: $font-weight-semi-bold;
            margin: unset;
        }

        .rlist--inline > li {
            display: inline-block;
        }
        @extend %gray-links;

        ~ div {
            font-style: italic;
        }

        + [role='paragraph'] {
            margin-top: 1em;
        }

        &__details-extra {
            .toc--link {
                padding-top: 0.625rem;
                display: block;
            }
        }
    }

    .cover-multiple-parents > li:not(:last-of-type) {
        margin-top: 1em;
        border-bottom: $border-solid-style $dark-7;
        margin-bottom: 1.25rem;
        padding-bottom: 1.25rem;
    }

    .truncate-list {
        button {
            padding: 0.125rem 0.625rem;
            min-height: unset;
            font-size: 0.675rem;
        }

        .read-less {
            font-size: $font-size-xxs;
            line-height: 1.15;
            margin: 0;
        }
    }

    .core-permissions .btn {
        margin-inline: 0;
    }

    .core-publisher {
        p {
            margin: 0;
        }
        .publisher__name {
            font-weight: $font-weight-semi-bold;
        }
    }

    .core-qualifiers li {
        text-transform: capitalize;
    }

    .core-sponsors,
    .core-in-cooperation {
        a {
            text-decoration: none;
            color: $dark-2;
        }
    }

    figure {
        padding: 0;

        &.graphic {
            text-align: center;
        }

        figcaption {
            text-align: justify;
        }
    }

    #core-collateral-info {
        .core-self-citation {
            .core-copyright {
                margin-top: 2rem;
            }
        }
    }

    .btn--pdf {
        background: $dark-red-color;
        &:hover {
            background: $red;
        }
    }
}

#core-collateral-references {
    .section--wrapper {
        padding: 0;
    }
    .label {
        color: $black;
        margin-inline-end: 0.375rem;
    }
    [role='listitem'] {
        padding-inline: 1.5rem;
        &:hover {
            background-color: $highlight;
        }
    }
}
.to-citation__wrapper .to-citation {
    text-decoration: underline;
}

#tab-citations {
    padding: 0;
    .citedByEntry {
        font-weight: $font-weight-light;
        padding: 1rem 1.5rem;
        &:not(.seeMoreWrapper):hover {
            background-color: $highlight;
        }
    }

    .cited-by__list li ~ li {
        border-block-start: $default-dashed-border;
    }

    .colored-block__title {
        padding-inline: 1.5rem;
    }
}

[aria-controls='article_sections_menu'] {
    border: none;
}

.otherMetrics__section__content {
    padding-left: 1rem;
}
