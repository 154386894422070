.figure-wrap {
    > header .label {
        display: block;
        align-content: center;
        a {
            color: $white;
            &:hover,
            &:focus,
            &:active {
                color: darken($white, 10%);
                text-decoration: underline;
            }
        }
    }

    &[data-has='anchor-boxed-text'] {
        background-color: transparent;
        margin: 0;
        overflow: inherit;
    }

    .figure-pop-btn {
        margin: 0;
    }

    header .label {
        color: $white;
        margin: 0;
        font-size: $font-size-sm;
    }

    .boxed-text {
        background: $boxed-text-bg;
        border: thin solid $boxed-text-border;

        figcaption {
            font-size: $font-size-md;
            font-weight: $font-weight-bold;
            margin: 0;
        }

        &[data-type='yellowish'] {
            background-color: $yellow-container;

            [role='paragraph'] {
                font-family: cursive;
            }
        }

        &[data-type='blueish'] {
            background: lightblue;
        }

        [data-style^='badge'] {
            background: transparent;
            color: $dark-5;
            font-size: $font-size-xs;
            margin-block: 0 0.75rem;
            padding: 0;
        }

        h4 {
            padding-top: 0;
        }
    }

    [data-position='anchor'],
    [data-type='pull-left'] {
        margin: 0 1em 1em 0;
    }
    [data-type='pull-right'] {
        margin: 0 0 1em 1em;
    }

    figure figcaption {
        font-weight: $font-weight-normal;
    }
}

figure {
    &.graphic {
        text-align: center;

        > img {
            display: inline-block;
        }
    }

    &.statement {
        margin: 2em;

        figcaption {
            display: inline-block;
            margin: 0;
            padding: 0 0.5rem 0 0;

            .smallcaps {
                font-variant: small-caps;
                font-size: medium;
            }
        }

        > div[role='paragraph'] {
            display: inline;
        }
    }

    figcaption {
        font-weight: $font-weight-normal;
    }
}

.core-container figure > figcaption,
.figure-wrap .footnote,
.figure-wrap .notes {
    text-align: inherit;
}
